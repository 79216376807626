var ImbalancesModalView = Backbone.View.extend({

    initialize:function(){
        var self = this;
        this.$ImbalancesModal = $("#ImbalancesModal");
        this.$ImbalancesModal.css("max-height", ($(window).height() - 180) + "px");
        this.$ogBlock = this.$ImbalancesModal.find(".og_block");
    },

    render:function(){
        this.bindEvents();
    },

    bindEvents:function(){
        var self = this;

        App.$ImbalancesGraph.on("click", ".block_head", function(){
            console.log("mobile block head click ?");
            self.selectBlockHead($(this));
            return false;
        });
        this.$ImbalancesModal.on("click", ".block_head", function(){
            self.selectBlockHead($(this));
            return false;
        });

        this.$ImbalancesModal.find(".modal_closebt").on("click", function(){
            App.shrinkBarWrapper();
        });
    },

    selectBlockHead:function($blockHead){
        var dataOg = $blockHead.attr("data-og");
        if(App.utils.countries[App.selectedLocation1].occupationgroups.all[dataOg] === undefined){
            alert("no data");
            return false;
        }
        $(".og_head .block_head").removeClass("selected");
        $blockHead.addClass("selected");
        console.log("selectBlockHead", $blockHead);
        $(".mobilespecific_picto").css("background-image", $blockHead.find(".head_picto").css("background-image"));
        $(".mobilespecific_label").html($blockHead.find(".label_str").html());

        if(App.isMobile){
            this.$ogBlockMobile.attr("data-block-index", $blockHead.attr("data-inc"));
            App.utils.animateOGBlock(this.$ogBlockMobile, dataOg, App.selectedLocation1, App.selectedLocation2);
        }else{
            this.$ogBlock.attr("data-block-index", $blockHead.attr("data-inc"));
            App.utils.animateOGBlock(this.$ogBlock, dataOg, App.selectedLocation1, App.selectedLocation2);    
        }
        
    },

    displayModal:function(talentID){
        var self = this;

        if(_.size(App.utils.countries[App.selectedLocation1].occupationgroups.all) == 0){
            console.log("no data ?");
            return false;
        }
        this.$ogBlock.find(".bar_point").css("left", "0%");
        this.$ImbalancesModal.find(".talent_label").html(App.utils.talents[talentID].label_en);
        this.$ImbalancesModal.find(".talent_description").html(App.utils.talents[talentID].description);
        this.$ImbalancesModal.find(".talent_highdemandlabel").html("In " + App.utils.countries[App.selectedLocation1].label_en + " : Hard-to-fill occupations using this skill");

        this.$ImbalancesModal.find(".og_blocks .og_head").empty();
        this.$ImbalancesModal.find(".og_blocks .og_head").append($("<div class='head_mobilespecific'><div class='mobilespecific_picto'></div><div class='mobilespecific_label'></div></div>"));

        _.each(App.utils.countries[App.selectedLocation1].talents.all[talentID].top3Occupationgroups, function(og, index){
            var headPictoImg = "img/fields/"+_.str.slugify(App.utils.occupationgroups[og.occupationgroup_code].label_en)+"@2x.png";
            self.$ImbalancesModal.find(".og_blocks .og_head").append($("<div class='block_head' data-og='"+og.occupationgroup_code+"' data-slug='"+_.str.slugify(App.utils.occupationgroups[og.occupationgroup_code].label_en)+"' data-inc='"+(index+1)+"'><div class='head_picto' style='background-image:url("+headPictoImg+");'></div><div class='head_label'><div class='label_num'>"+(index+1)+"</div><div class='label_str'>"+App.utils.occupationgroups[og.occupationgroup_code].label_en+"</div></div></div>"));
            

            /*var $ogBlock = App.utils.getOGBlock(og.occupationgroup_code, App.selectedLocation1, App.selectedLocation2, index);
            self.$ImbalancesModal.find(".og_blocks").append($ogBlock);*/
        });

        this.$ImbalancesModal.addClass("displayed");
        setTimeout(function(){
            self.$ImbalancesModal.addClass("disopacified");
        }, 100);
        this.appendModalToMobileSubbars();

        setTimeout(function(){
            if(App.isMobile){
                console.log($(".modalmobile").find(".block_head")[0]);
                self.selectBlockHead($($(".modalmobile").find(".block_head")[0]));
            }else{
                self.selectBlockHead($(self.$ImbalancesModal.find(".block_head")[0]));
            }
        }, 100);
    },

    appendModalToMobileSubbars:function(){
        var $graphBarWrapper = $(".graph_bar_wrapper[data-talent='"+App.selectedTalentID+"']");

        var modalMobile = "<div class='modalmobile'>"+$("#ImbalancesModal").html()+"</div>";
        $graphBarWrapper.find(".graph_subbars").append(modalMobile);
        this.$ogBlockMobile = $(".modalmobile .og_block");
    },

    hideModal:function(){
        var self = this;
        this.$ImbalancesModal.removeClass("disopacified");
        setTimeout(function(){
            self.$ImbalancesModal.removeClass("displayed");
        }, 600);
    },

});

module.exports = ImbalancesModalView;