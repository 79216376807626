var AppRouter = Backbone.Router.extend({

    routes: {
        "":"routeFromURL",
        ":location":"routeFromURL",
        ":location/:myoccupation":"routeFromURL",
        ":location/:myoccupation/:wishoccupation":"routeFromURL",
        ":location/:myoccupation/:wishoccupation/":"routeFromURL",
    },

    routeFromURL:function(location, myoccupation, wishoccupation){
        if(location !== null && location !== undefined){
            App.selectedLocation = location;
            if(location !== "_"){
                App.isValidatedLocation = true;
            }
        }
        if(myoccupation !== null && myoccupation !== undefined){
            App.selectedMyOccupation = myoccupation;
            if(myoccupation !== "_"){
                App.isValidatedMyOccupation = true;
            }
        }
        if(wishoccupation !== null && wishoccupation !== undefined){
            App.selectedWishOccupation = wishoccupation;
            if(wishoccupation !== "_"){
                App.isValidatedWishOccupation = true;
            }
        }
        App.updateFromRouter();
    },
    
    updateRoute:function(triggerize){
        this.navigate( App.selectedLocation + "/" + App.selectedMyOccupation + "/" + App.selectedWishOccupation, {trigger: triggerize});
    },

});

module.exports = AppRouter;