var AppRouter = Backbone.Router.extend({

    routes: {
        "":"routeFromURL",
        ":location":"routeFromURL",
        ":location/":"routeFromURL",
        ":location/:location2":"routeFromURL",
        ":location/:location2/":"routeFromURL",
        ":location/:location2/:talents":"routeFromURL",
        ":location/:location2/:talents/":"routeFromURL",
        ":location/:location2/:talents/:comparison":"routeFromURL",
        ":location/:location2/:talents/:comparison/":"routeFromURL",
    },

    routeFromURL:function(location1, location2, talents, comparison){
        console.log("routeFromURL", location1, location2, talents, comparison);
        if(location1 !== null && location1 !== undefined && location1 !== "_") App.selectedLocation1 = location1;
        if(location2 !== null && location2 !== undefined) App.selectedLocation2 = location2;
        if(talents !== null && talents !== undefined) App.selectedTalents = JSON.parse(talents);
        if(comparison !== null && comparison !== undefined) App.selectedComparisonType = comparison;
        App.updateFromRouter();
    },
    
    updateRoute:function(triggerize){
        this.navigate( App.selectedLocation1 + "/" + App.selectedLocation2 + "/" + encodeURIComponent(JSON.stringify(App.selectedTalents)) + "/" + App.selectedComparisonType, {trigger: triggerize});
    },

});

module.exports = AppRouter;