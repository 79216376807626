var App = {
    $body:$("body"),

    init: function init() {
        this.$App = $("#App");
        this.$locateuserBt = $(".locateuser_bt");
        this.$LocationSelectbox = $("#LocationSelectbox");

        this.selectedLocation = "_";
        this.selectedStep = "1";
        this.isAutoLocated = true;
        this.mapZoomed = false;

        this.currentZoom = 1;
        this.currentScale = 1;
        this.countryZoomScale = 4;

        var Utils = require("utils");
        this.utils = new Utils();

        var HeaderView = require("views/headerView");
        this.headerView = new HeaderView();

        var Router = require("router");
        this.router = new Router();

        Backbone.history.start();
    },

    updateFromRouter:function(){
        var self = this;

        this.utils.getDBCountires(function(){
            self.utils.setAutocompleteCountries();
            self.render();
        });
        /*this.utils.gatherData(function(){
            self.render();
        });*/
    },

    render:function(){
        this.headerView.render();
        this.selectedLocation = USER_COUNTRY;
        this.renderLocationSelectbox();
        this.renderMap();
        this.bindEvents();
        this.update();
    },

    renderLocationSelectbox:function(){
        var self = this;
        _.each(App.utils.countries, function(c){
            self.$LocationSelectbox.append("<option value='"+c.code+"'>"+c.label_en+"</option>");
        });
    },

    renderMap:function(){
        var self = this;

        this.countriesSeries = _.map(this.utils.countries, function(c){
            if(c.mapped == "1"){
                return {
                    name:c.label_en,
                    code:c.code,
                    id:c.code,
                    lat:parseFloat(c.lat),
                    lon:parseFloat(c.lng)
                };
            }else{
                return undefined
            }
        });
        this.countriesSeries = _.without(this.countriesSeries, undefined);

        this.worldMap = new Highcharts.mapChart({
            chart: {
                map: 'custom/world-highres',
                renderTo:"WorldMap",
                backgroundColor:"#efefeb",
                spacing:[0,0,0,0],
                animation:false
            },
            title: { text: '' },
            legend:{enabled:false},
            mapNavigation: { enabled: true },
            credits:{ enabled:false},
            tooltip: {
                headerFormat: '',
                pointFormat: '<b>{point.name}</b><br>Lat: {point.lat}, Lon: {point.lon}'
            },

            plotOptions:{
                map:{
                    color:'#dadad0'
                },
                mappoint:{
                    marker:{
                        symbol:"url(img/map-pin@2x.png)",
                        width:20,
                        height:24,
                        states:{
                            hover:{
                                enabled:false
                            }
                        }
                    },
                    dataLabels:{
                        enabled:false
                    },
                    events:{
                        click:function(e,u){
                            self.selectLocation(e.point.options.code);
                        }
                    }
                }
            },
            tooltip:{
                enabled:false
            },
            series: [{
                // Use the gb-all map with no data as a basemap
                name: 'Basemap',
                borderColor: '#ffffff',
                nullColor: '#dadad0',
                showInLegend: false
            }, {
                name: 'Separators',
                type: 'mapline',
                nullColor: '#cfdb7a',
                showInLegend: false,
                enableMouseTracking: false
            }, {
                // Specify points using lat/lon
                type: 'mappoint',
                name: 'Cities',
                data: this.countriesSeries
            }]
        });

        _.each($(".highcharts-markers.highcharts-series-2 image"), function(svgImg, index){
            $(svgImg).attr("data-country", App.countriesSeries[index].code);
        });
    },

    zoomToCountry:function(countryCode){
        var self = this;
        $("image.highcharts-point").attr("data-selected", "false")
                                    .attr("width", "20")
                                    .attr("height", "24");
        
        var countryCenter = App.utils.getHighmapsCountryCenter(countryCode);
        var zoom = 1 / (this.countryZoomScale / this.currentScale);
        self.worldMap.mapZoom(zoom, countryCenter.x, countryCenter.y, 0, 0);

        $("image.highcharts-point[data-country='"+countryCode+"']").attr("data-selected", "true")
                                                                    .attr("width", "40")
                                                                    .attr("height", "48");
        this.currentScale = this.countryZoomScale;
    },

    update:function(){
        var self = this;
        
        if(this.selectedLocation !== "_") this.zoomToCountry(this.selectedLocation);

        this.$App.attr("data-autolocated", this.isAutoLocated);
        $(".mainblock_step").removeClass("displayed");
        $(".mainblock_step[data-step='"+this.selectedStep+"']").addClass("displayed");

        this.updateLocationAutocomplete();
        this.updateChoiceBts();
        this.router.updateRoute();
    },

    updateLocationAutocomplete:function(){
        if(App.utils.countries[this.selectedLocation] !== undefined){
            $(".locateuser_country").html(App.utils.countries[this.selectedLocation].label_en);
        }
    },

    updateChoiceBts:function(){
        $(".choice_bt[data-choice='imbalances']").attr("href", "imbalances.php#"+this.selectedLocation);
        $(".choice_bt[data-choice='career']").attr("href", "changing_career.php#"+this.selectedLocation);
    },

    bindEvents:function(){
        var self = this;

        this.$locateuserBt.on("click", function(){
            navigator.geolocation.getCurrentPosition(function(position) {
                /*console.log(position);
                $.getJSON("http://ws.geonames.org/countryCode?lat=43.7534932&lng=28.5743187&type=JSON*/
            });
        });

        $(".mainblock_changelocationbt").on("click", function(){
            self.selectedStep = "2";
            self.update();
        });

        this.$LocationSelectbox.on("change", function(){
            var boxValue = $(this).val()
            if(boxValue !== "default"){
                self.isAutoLocated = false;
                self.selectedLocation = $(this).val();
                self.selectedStep = "1";
                self.update();
            }
        });

        $(".pickmap_cancelbt").on("click", function(){
            self.selectedStep = "1";
            self.update();
        });

        $(".nav_bt[data-bt='zoomin']").on("click", function(){
            self.zoomIn();
        });

        $(".nav_bt[data-bt='zoomout']").on("click", function(){
            self.zoomOut();
        });

        $(".nav_bt[data-bt='zoomzero']").on("click", function(){
            self.zoomZero();
        });
    },

    zoomIn:function(){
        this.currentScale = this.currentScale*2;
        App.worldMap.mapZoom(0.5);
    },

    zoomOut:function(){
        this.currentScale = this.currentScale/2;
        App.worldMap.mapZoom(2);
    },

    zoomZero:function(){
        this.currentScale = 1;
        App.worldMap.mapZoom();
    },

    selectLocation:function(countryCode){
        this.selectedLocation = countryCode;
        this.update();
    }

};

module.exports = App;
window.App = App;