var HeaderView = Backbone.View.extend({

    initialize:function(){
        var self = this;

    },

    render:function(){
        this.renderTwitterMessage();
        this.bindEvents();
    },

    renderTwitterMessage:function(){
        var hostWebsite = encodeURIComponent(location.href);
        var shareMSG = encodeURIComponent('What skills are in demand in your labour market? …and what will your next occupation be? Take a look at http://ocde.wedodata.fr/skills #skills');
        $(".share_bt[data-share='tw']").attr("href", "https://twitter.com/intent/tweet?url="+hostWebsite+"&text="+shareMSG);        
    },

    update:function(location){
        $(".header_link[data-page='imbalances']").attr("href", "imbalances.php#"+location);
        $(".header_link[data-page='changing_career']").attr("href", "changing_career.php#"+location);
        $(".header_link[data-page='methodology']").attr("href", "methodology.php#"+location);
        $(".header_link[data-page='press']").attr("href", "press.php#"+location);
    },

    bindEvents:function(){
        var self = this;

        $(".burgermenubt").on("click", function(){
            $("#Header").toggleClass("burgered");
        	$(".header_links").toggleClass("displayedmobile");
        });

        $(".share_bt[data-share='fb']").on("click", function(){
            FB.ui(
                { 
                    method: 'share',
                    href: 'http://ocde.wedodata.fr/skills',
                    display: 'popup',
                    //quote:'mensagge'
                }, 
                function(response){ console.log(response); }
            );
        });
    },

});

module.exports = HeaderView;