var Utils = Backbone.View.extend({

    initialize:function(){
        var self = this;
        this.countriesArray = [];
        this.countries = {};
        this.autocompleteCountries = [];

        this.talentsArray = [];
        this.talents = {};
        this.lvl1TalentsArray = [];
        this.lvl1Talents = {};
        this.lvl2TalentsArray = [];
        this.lvl2Talents = {};

        this.occupationsArray = [];
        this.occupations = {};
        this.occupationgroupsArray = [];
        this.occupationgroups = {};

        this.translations = {
            "employment":"Employment growth",
            "hours_worked":"Hours worked growth",
            "wages":"Wage growth",
            "unemployment_rate":"Unemployment rate",
            "underqualification":"Change in underqualification"
        };

        this.absMaxOccupationIndicator = 3;
        this.maxTalentLevel = 7;

        this.talentsHelptext = {};
    },

    gatherTalentsHelptexts:function(callback){
        var self = this;
        d3.csv("data/talents_helptexts.csv", function(data){
            var slugTalent = _.str.slugify(_.str.trim(data.talent));
            self.talentsHelptext[slugTalent] = [];
            for(var i=1 ;i<=7 ; i++){
                if(data["help_"+i] !== "") self.talentsHelptext[slugTalent].push({
                    lvl:i,
                    txt:data["help_"+i]
                });
            }
        }, function(error, rows) {
            console.log(error, rows);
            return callback();
        });
    },

    gatherData:function(callback){
        var self = this;
        //return callback();
        this.gatherStaticData(function(){
            return callback();
        });
    },

    gatherStaticData:function(callback){
        var self = this;
        this.setCountries(function(){
            self.setTalents(function(){
                self.setAllOccupations(function(){
                    return callback();
                });
            });
        });
    },

    setCountries:function(callback){
        var self = this;
        $.ajax({
            url:"api/getCountries.php",
            success:function(data){
                self.countriesArray = JSON.parse(data);
                _.each(self.countriesArray, function(c){
                    c.talents = {
                        all:{},
                        lvl1:{},
                        lvl2:{}
                    };
                    c.occupationgroups = {
                        all:{}
                    };
                    self.countries[c.code] = c;
                });
                return callback();
            }
        });
    },

    setAutocompleteCountries:function(){
        var self = this;
        _.each(this.countries, function(c){
            self.autocompleteCountries.push({
                value:c.code, 
                label:c.label_en
            });
        });
    },

    setAutocompleteOccupations:function(){
        this.autocompleteOccupations = _.map(this.occupationsArray, function(o){
            return {
                value:o.code,
                label:o.label_en
            }
        });
    },

    setTalents:function(callback){
        var self = this;
        $.ajax({
            url:"api/getTalents.php",
            success:function(data){
                self.talentsArray = JSON.parse(data);
                _.each(self.talentsArray, function(t){
                    var talentParent = "";
                    var talentID = t.id
                    if(t.type == "abilities" && t.level == "c") talentParent = talentID.slice(0, -2);
                    else if( (t.type == "skills" || t.type == "knowledge") && t.level == "b") talentParent = talentID.slice(0, -2);
                    self.talents[t.id] = t;
                    self.talents[t.id].parent = talentParent;
                });

                self.lvl1TalentsArray = _.filter(self.talentsArray, function(t){
                    if(t.type == "skills" || t.type == "knowledge"){
                        if(t.level == "a") return true;
                        return false
                    }else{
                        if(t.level == "b") return true;
                        return false;
                    }
                });
                _.each(self.lvl1TalentsArray, function(t){
                    self.lvl1Talents[t.id] = t;
                })

                self.lvl2TalentsArray = _.filter(self.talentsArray, function(t){
                    if(t.type == "skills" || t.type == "knowledge"){
                        if(t.level == "b") return true;
                        return false
                    }else{
                        if(t.level == "c") return true;
                        return false;
                    }
                });
                _.each(self.lvl2TalentsArray, function(t){
                    self.lvl2Talents[t.id] = t;
                })

                return callback();
            }
        });
    },

    setAllOccupations:function(callback){
        var self = this;
        $.ajax({
            url:"api/getAllOccupations.php",
            success:function(data){
                var allOccupations = JSON.parse(data);
                self.occupationsArray = allOccupations.occupation;
                _.each(self.occupationsArray, function(o){
                    self.occupations[o.code] = o;
                });

                self.occupationgroupsArray = allOccupations.occupationgroup;
                _.each(self.occupationgroupsArray, function(o){
                    self.occupationgroups[o.code] = o;
                });

                return callback();
            }
        });
    },

    getDBCountryTalents:function(countryCode, callback){
        var self = this;
        if(_.size(this.countries[countryCode].talents.all) === 0){
            $.ajax({
                url:"api/getCountryTalents.php",
                data:{country_code:countryCode},
                method:"POST",
                success:function(data){
                    var talentsArray = JSON.parse(data);
                    var talentsObj = {};
                    var talentsObjLvl1 = {};
                    var talentsObjLvl2 = {};

                    _.each(talentsArray, function(t){
                        talentsObj[t.talent_id] = {
                            talent_id:t.talent_id,
                            final_ind:parseFloat(t.final_ind.replace(",","."))
                        };

                        if(self.lvl1Talents[t.talent_id] !== undefined){
                            talentsObjLvl1[t.talent_id] = {
                                talent_id:t.talent_id,
                                final_ind:parseFloat(t.final_ind.replace(",","."))
                            };
                        }else if(self.lvl2Talents[t.talent_id] !== undefined){
                            talentsObjLvl2[t.talent_id] = {
                                talent_id:t.talent_id,
                                final_ind:parseFloat(t.final_ind.replace(",","."))
                            };
                        }
                    });

                    self.countries[countryCode].talents = {
                        all:talentsObj,
                        lvl1:talentsObjLvl1,
                        lvl2:talentsObjLvl2,
                    }

                    return callback();
                }
            });
        }else{
            return callback();
        }
    },

    getDBCountryOccupationgroups:function(countryCode, callback){
        var self = this;
        if(_.size(this.countries[countryCode].occupationgroups.all) === 0){
            $.ajax({
                url:"api/getCountryOccupationgroups.php",
                data:{country_code:countryCode},
                method:"POST",
                success:function(data){
                    var occupationgroupsArray = JSON.parse(data);
                    var occupationgroupsObj = {};
                    console.log("getDBCountryOccupationgroups", occupationgroupsArray);
                    _.each(occupationgroupsArray, function(og){
                        occupationgroupsObj[og.occupationgroup_code] = {
                            occupationgroup_code:og.occupationgroup_code,
                            final_ind:parseFloat(og.final_ind.replace(",",".")),
                            indicators:{
                                employment:parseFloat(og.employment.replace(",",".")),
                                hours_worked:parseFloat(og.hours_worked.replace(",",".")),
                                wages:parseFloat(og.wages.replace(",",".")),
                                underqualification:parseFloat(og.underqualification.replace(",",".")),
                                unemployment_rate:parseFloat(og.unemployment_rate.replace(",",".")),
                            }
                        };
                    });
                    self.countries[countryCode].occupationgroups = {
                        all:occupationgroupsObj,
                    }

                    return callback();
                }
            });
        }else{
            return callback();
        }
    },

    getDBCountryTalentOccupationgroups:function(countryCode, talentID, callback){
        var self = this;
        console.log("getDBCountryTalentOccupationgroups", countryCode, talentID);
        if(this.countries[countryCode].talents.all[talentID].occupationgroups === undefined){
            $.ajax({
                url:"api/getCountryTalentOccupationgroups.php",
                data:{country_code:countryCode, talent_id:talentID},
                method:"POST",
                success:function(data){
                    var jsonData = JSON.parse(data);
                    var occupationgroups = [];
                    _.each(jsonData, function(ogData){
                        ogData.value = parseFloat(ogData.value.replace(",","."));
                        ogData.occupationgroup_indicator = parseFloat(ogData.occupationgroup_indicator.replace(",","."));
                        ogData.talent_indicator = parseFloat(ogData.talent_indicator.replace(",","."));
                        occupationgroups.push(ogData);
                    });
                    self.countries[countryCode].talents.all[talentID].occupationgroups = occupationgroups;
                    self.setTop3OccupationGroupsFromCountryTalent(countryCode, talentID);
                    return callback();
                }
            });
        }else{
            return callback();
        }
    },

    getDBOccupationTalents:function(occupationCode, callback){
        var self = this;
        console.log("getDBOccupationTalents", occupationCode);
        if(this.occupations[occupationCode].talents === undefined){
            $.ajax({
                url:"api/getOccupationTalents.php",
                data:{occupation_code:occupationCode},
                method:"POST",
                success:function(data){
                    var jsonData = JSON.parse(data);
                    var occupationTalents = {};
                    _.each(jsonData, function(otData){
                        occupationTalents[otData.talent_id] = {
                            talent_id:otData.talent_id,
                            level:parseFloat(otData.level.replace(",","."))
                        }
                    });
                    self.occupations[occupationCode].talents = occupationTalents;
                    return callback();
                }
            });
        }else{
            return callback();
        }
    },

    getDBCountires:function(callback){
        var self = this;
        $.ajax({
            url:"api/getCountries.php",
            success:function(data){
                self.countriesArray = JSON.parse(data);
                _.each(self.countriesArray, function(c){
                    self.countries[c.code] = c;
                });
                return callback();
            }
        });
    },

    getChildrenTalents:function(talentID){
        return _.filter(this.lvl2Talents, function(t){
            return t.parent == talentID;
        });
    },

    setTop3OccupationGroupsFromCountryTalent:function(countryCode, talentID){
        var talentData = App.utils.countries[countryCode].talents.all[talentID];

        var talentSign = "positive";
        if(talentData.final_ind < 0) talentSign = "negative";
        var sameSignedOccupationgroups = _.filter(talentData.occupationgroups, function(og){
            if(talentSign == "positive"){
                return og.occupationgroup_indicator >= 0 && og.talent_indicator >= 0;
            }else{
                return og.occupationgroup_indicator <= 0 && og.talent_indicator <= 0;
            }
        });

        var sortedSameSigned = _.sortBy(sameSignedOccupationgroups, function(og){
            return -Math.abs(og.value);
        });
        var top3Occupationgroups = sortedSameSigned.slice(0,3);

        App.utils.countries[countryCode].talents.all[talentID].top3Occupationgroups = top3Occupationgroups;
    },

    getTop5OccupationsDiff:function(myOccupationTalents, wishOccupationTalents){
        var occupationsDiffValues = {};
        console.log("myOccupationTalents", myOccupationTalents, wishOccupationTalents);
        _.each(myOccupationTalents, function(talentData, talentID){
            occupationsDiffValues[talentID] = {
                talent_id:talentID,
                myLevel:talentData.level,
                wishLevel:wishOccupationTalents[talentID].level,
                diff:wishOccupationTalents[talentID].level - talentData.level
            }
        });

        var sortedDiffs = _.sortBy(occupationsDiffValues, function(oDiff){
            return -oDiff.diff;
        })
        return sortedDiffs.slice(0,5);
    },

    fillAutocomplete:function($input, source, callbackFunc){
        console.log("fillAutocomplete", $input, source, callbackFunc);
        $input.autocomplete({
          minLength: 2,
          position:{ my: "left top", at: "left bottom", collision: "none" },
          source: source,
          focus: function( event, ui ) {
            $input.val(ui.item.label);
            return false;
          },
          select: function( event, ui ) {
            $input.val(ui.item.label);
            if(callbackFunc == "selectLocation") App.selectLocation(ui.item.value);
            else if(callbackFunc == "selectMyOccupation") App.selectMyOccupation(ui.item.value);
            else if(callbackFunc == "selectWishOccupation") App.selectWishOccupation(ui.item.value);
            return false;
          }
        })
        .focus(function () {
            $(this).autocomplete("search");
        })
        .autocomplete( "instance" )._renderItem = function( ul, item ) {
          /*return $( "<li>" )
            .append( "<div>" + item.label + "<br>" + item.desc + "</div>" )
            .appendTo( ul );*/
            // Replace the matched text with a custom span. This
            // span uses the class found in the "highlightClass" option.
            var re = new RegExp( "(" + this.term + ")", "gi" ),
                cls = "highlight",
                template = "<span class='" + cls + "'>$1</span>",
                label = item.label.replace( re, template ),
                $li = $( "<li/>" ).appendTo( ul );

            // Create and return the custom menu item content.
            $( "<div/>" ).html( label ).appendTo( $li );

            return $li;
        };
    },

    getHighmapsCountryCenter:function(country){
        var lowerCountry = country.toLowerCase();
        var foundCountryHighmaps = _.find(App.worldMap.series[2].points, function(p){
            return p.options.code.toLowerCase() == lowerCountry;
        });

        var worldMapOffset = $("#WorldMap").offset();

        if(foundCountryHighmaps !== undefined){
            return {
                x:foundCountryHighmaps.x + worldMapOffset.left,
                y:foundCountryHighmaps.y + worldMapOffset.top,
            };
        }else{
            return undefined;
        }
    },

    animateOGBlock:function($ogBlock, ogCode, location1, location2){
        var inc = 1;
        var incTimeout = 100;
        var shift = 400;
        _.each(App.utils.countries[location1].occupationgroups.all[ogCode].indicators, function(indValue, indicatorID){
            if(!isNaN(indValue)){
                $ogBlock.find(".content_indicator[data-indicator='"+indicatorID+"']").attr("data-hasdata", "true");
                var $contentIndicator = $ogBlock.find(".content_indicator[data-indicator='"+indicatorID+"']");

                var pointPosition = 50 + ((indValue*50) / App.utils.absMaxOccupationIndicator);
                if(indValue < 0){
                    pointPosition = 50 - ( (Math.abs(indValue)*50) / App.utils.absMaxOccupationIndicator);
                }
                
                setTimeout(function(){
                    $contentIndicator.find(".bar_point").css("left", pointPosition+"%");
                }, incTimeout*inc);
                
                var $barPoint2 = "";
                if(location2 !== "_" && location2 !== undefined){
                    if(_.size(App.utils.countries[location2].occupationgroups.all) > 0 && App.utils.countries[location2].occupationgroups.all[ogCode] !== undefined){
                        if(App.utils.countries[location2].occupationgroups.all[ogCode].indicators[indicatorID] !== undefined && !isNaN(App.utils.countries[location2].occupationgroups.all[ogCode].indicators[indicatorID])){
                            var indValue2 = App.utils.countries[location2].occupationgroups.all[ogCode].indicators[indicatorID];
                            console.log("indValue loc2", indValue);
                            var pointPosition2 = 50 + ((indValue2*50) / App.utils.absMaxOccupationIndicator);
                            if(indValue < 0){
                                pointPosition2 = 50 - ( (Math.abs(indValue2)*50) / App.utils.absMaxOccupationIndicator);
                            }
                            setTimeout(function(){
                                $contentIndicator.find(".bar_point2").css("left", pointPosition2+"%");
                            }, 400 + (incTimeout*inc)) 
                        }
                    }
                }
                inc += 1;
            }else{
                $ogBlock.find(".content_indicator[data-indicator='"+indicatorID+"']").attr("data-hasdata", "false");
            }
        });
    }
    
});

module.exports = Utils;