var App = {
    $body:$("body"),

    init: function init() {
        this.$App = $("#App");
        this.$Container = $("#Container");
        this.$ContainerArian = $("#ContainerArian");
        this.$ChangingcareerTalents = $("#ChangingcareerTalents");
        this.$Cache = $("#Cache");
        this.$OGModal = $("#OGModal");
        this.$ogBlock = this.$OGModal.find(".og_block");
        this.$locationSelectbox = $(".location_selectbox");
        this.page = "changing_career";

        this.selectedContainerBlock = "";
        this.selectedLocation = "_";
        this.isValidatedLocation = false;
        this.selectedMyOccupation = "_";
        this.isValidatedMyOccupation = false;
        this.selectedWishOccupation = "_";
        this.isValidatedWishOccupation = false;

        this.$locationSelectbox = $(".location_selectbox");
        this.$talentGraph = $(".talent_graph");
        
        var Utils = require("utils");
        this.utils = new Utils();

        var HeaderView = require("views/headerView");
        this.headerView = new HeaderView();

        var Router = require("routerChangingCareer");
        this.router = new Router();

        Backbone.history.start();
    },

    updateFromRouter:function(){
        var self = this;
        if(this.selectedLocation !== "_") this.isValidatedLocation = true;
        this.utils.gatherData(function(){
            self.utils.setAutocompleteCountries();
            self.utils.setAutocompleteOccupations();
            self.utils.gatherTalentsHelptexts(function(){
                self.render();
            });  
        });
    },

    render:function(){
        $(".header_link").removeClass("selected");
        $(".header_link[data-page='"+this.page+"']").addClass("selected");
        this.headerView.render();

        this.renderLocationSelectbox();
        //this.utils.fillAutocomplete($("#LocationSelectorInput"), App.utils.autocompleteCountries, "selectLocation");
        this.utils.fillAutocomplete($("#ChangingcareerOccupationsInput"), App.utils.autocompleteOccupations, "selectMyOccupation");
        this.utils.fillAutocomplete($("#WishInput"), App.utils.autocompleteOccupations, "selectWishOccupation");

        this.bindEvents();
        this.update();
    },

    renderLocationSelectbox:function(){
        var self = this;
        _.each(App.utils.countries, function(c){
            var $option = $("<option value='"+c.code+"'>"+c.label_en+"</option>");
            self.$locationSelectbox.append($option);
        });
        self.$locationSelectbox.prepend("<option value='_'>Choose country</option>");
        self.$locationSelectbox.val(self.selectedLocation);
    },

    update:function(){
        var self = this;

        if(this.selectedContainerBlock == ""){
            this.selectedContainerBlock = "location";
        }

        if(this.selectedContainerBlock == "location"){
            this.updateContent();
            if(this.selectedLocation == "_"){
                $(".location_validbt").removeClass("displayed");
            }else{
                if(!this.isValidatedLocation){
                    $(".location_validbt").addClass("displayed");
                }else{
                    this.selectedContainerBlock = self.findNextEmptyStep();
                    this.update();
                }
            }
        }else if(this.selectedContainerBlock == "currentwork"){
            console.log("puis là", this.selectedMyOccupation);
            if(this.selectedMyOccupation == "_"){
                this.updateContent();
            }else{
                this.selectedContainerBlock = "currentworkValidate";
                this.update();
            }
        }else if(this.selectedContainerBlock == "currentworkValidate"){
            if(!this.isValidatedMyOccupation){
                var occupationGroup = App.utils.occupations[this.selectedMyOccupation].parent;
                var slugOccupationGroup = _.str.slugify(App.utils.occupationgroups[occupationGroup].label_en);
                $("#ValidateChangingcareerOccupations .subtitle_picto").css("background-image", "url('img/fields/"+slugOccupationGroup+"@2x.png')");
                $("#ValidateChangingcareerOccupations .occupation_label").html(App.utils.occupations[this.selectedMyOccupation].label_en);
                this.updateContent();
            }else{
                this.selectedContainerBlock = self.findNextEmptyStep();
                this.update();
            }  
        }else if(this.selectedContainerBlock == "wish"){
            if(this.selectedWishOccupation == "_"){
                this.updateContent();
            }else{
                this.selectedContainerBlock = "wishValidate";
                this.update();
            }
        }else if(this.selectedContainerBlock == "wishValidate"){
            if(!this.isValidatedWishOccupation){
                var occupationGroup = App.utils.occupations[this.selectedWishOccupation].parent;
                var slugOccupationGroup = _.str.slugify(App.utils.occupationgroups[occupationGroup].label_en);
                $("#ValidateWishOccupations .subtitle_picto").css("background-image", "url('img/fields/"+slugOccupationGroup+"@2x.png')");
                $("#ValidateWishOccupations .occupation_label").html(App.utils.occupations[this.selectedWishOccupation].label_en);
                this.selectedContainerBlock = "wishValidate";
                this.updateContent();
            }else{
                this.selectedContainerBlock = self.findNextEmptyStep();;
                this.update();
            }
        }else if(this.selectedContainerBlock == "results"){
            self.utils.getDBCountryOccupationgroups(self.selectedLocation, function(){
                self.utils.getDBOccupationTalents(self.selectedMyOccupation, function(){
                    self.utils.getDBOccupationTalents(self.selectedWishOccupation, function(){
                        //self.updateOccupations();
                        console.log("ici ?");
                        if(_.size(App.utils.occupations[self.selectedMyOccupation].talents) == 0){
                            self.selectedContainerBlock = "currentwork";
                            self.selectedMyOccupation = "_";
                            self.isValidatedMyOccupation = false;
                            alert("no data for mine");
                            self.update();
                        }else if(_.size(App.utils.occupations[self.selectedWishOccupation].talents) == 0){
                            self.selectedContainerBlock = "wish";
                            self.selectedWishOccupation = "_";
                            self.isValidatedWishOccupation = false;
                            alert("no data for wish");
                            self.update();
                        }else{
                            self.updateContent();
                            self.top5OccupationsDiff = App.utils.getTop5OccupationsDiff(App.utils.occupations[self.selectedMyOccupation].talents, App.utils.occupations[self.selectedWishOccupation].talents);
                            self.updateTalents();
                        }
                    });
                });
            });
        }

        this.headerView.update(this.selectedLocation);
        this.router.updateRoute();
    },

    updateContent:function(){
        var self = this;
        $(".container_block").addClass("leave");
        setTimeout(function(){
            $(".container_block").removeClass("displayed")
                                .removeClass("leave");
            $(".container_block[data-block='"+self.selectedContainerBlock+"']").addClass("displayed");
            $(".container_block[data-block='"+self.selectedContainerBlock+"'] .block_input").val("");
            self.$Container.attr("data-block", self.selectedContainerBlock);
            self.updateArian();
        }, 300);

    },

    updateArian:function(){
        $(".arian_block").removeClass("selected");
        $(".arian_value").html("");
        if(this.selectedLocation !== "_"){
            $(".arian_block[data-block='location']").addClass("selected");
            $(".arian_block[data-block='location'] .arian_value").html(App.utils.countries[this.selectedLocation].label_en);
        }

        if(this.selectedMyOccupation !== "_" && this.isValidatedMyOccupation){
            this.selectedMyOccupationGroup = App.utils.occupations[this.selectedMyOccupation].parent;

            $(".arian_block[data-block='currentwork']").addClass("selected");
            $(".arian_block[data-block='currentwork'] .arian_value.arian_value_desktop").html(_.str.prune(App.utils.occupations[this.selectedMyOccupation].label_en, 40) + "<div class='value_group' data-group='"+this.selectedMyOccupationGroup+"'>Statistics</div>");
            $(".arian_block[data-block='currentwork'] .arian_value.arian_value_mobile").html(App.utils.occupations[this.selectedMyOccupation].label_en + "<div class='value_group' data-group='"+this.selectedMyOccupationGroup+"'>Statistics</div>");
        }

        if(this.selectedWishOccupation !== "_" && this.isValidatedWishOccupation){
            this.selectedWishOccupationGroup = App.utils.occupations[this.selectedWishOccupation].parent;

            $(".arian_block[data-block='wish']").addClass("selected");
            $(".arian_block[data-block='wish'] .arian_value").html(_.str.prune(App.utils.occupations[this.selectedWishOccupation].label_en, 40) + "<div class='value_group' data-group='"+this.selectedWishOccupationGroup+"'>Statistics</div>");
        }
    },

    updateTalents:function(){
        var self = this;
        self.$talentGraph.empty();

        _.each(this.top5OccupationsDiff, function(oDiffData, index){
            var $talentRow = $("<div class='talent_row' data-row='"+index+"'><div class='talent_label'><div class='label_value'>"+App.utils.talents[oDiffData.talent_id].label_en+"&nbsp;<span class='talent_help' data-row='"+index+"'>?</span></div></div></div>");
            
            var $talentBarWrapper = $("<div class='talent_bar_wrapper'><div class='talent_bar'><div class='bar_sep' data-sep='0'></div><div class='bar_sep' data-sep='1'></div><div class='bar_sep' data-sep='2'></div><div class='bar_sep' data-sep='3'></div><div class='bar_sep' data-sep='4'></div><div class='bar_sep' data-sep='5'></div><div class='bar_sep' data-sep='6'></div><div class='bar_sep' data-sep='7'></div><div class='bar_inner'><div class='bar_arrow'></div></div></div><a class='help_mobileclosebt'></a></div>");

            var inc = 0;
            var slugTalent = _.str.slugify(App.utils.talents[oDiffData.talent_id].label_en)
            _.each(App.utils.talentsHelptext[slugTalent], function(helpData){
                var $barSep = $talentBarWrapper.find(".bar_sep[data-sep='"+helpData.lvl+"']");
                $barSep.append("<div class='sep_help'><div class='help_text'>"+helpData.txt+"</div><div class='help_level'>Level "+helpData.lvl+"</div></div>");
                if(inc%2 > 0){
                    $barSep.find(".sep_help").addClass("tobottom");
                }
                inc += 1;
            })

            var $barArrow = $talentBarWrapper.find(".bar_arrow");

            var arrowWidth = (oDiffData.diff * 100) / 7;
            var arrowLeft = (oDiffData.myLevel * 100) / 7;
            $barArrow.css("left", arrowLeft+"%")
                    .css("width", "1%");
            setTimeout(function(){
                $barArrow.css("width", arrowWidth+"%");
            }, ((index+1)*400));

            $talentRow.append($talentBarWrapper);
            self.$talentGraph.append($talentRow);
        });
    },

    bindEvents:function(){
        var self = this;

         this.$ContainerArian.on("click", ".arian_block, .arian_modifybt, .arian_mobilelabel", function(){
            console.log("toto ?", $(this));
            var dataBlock = $(this).attr("data-block");
            if(dataBlock == "location"){
                self.selectedLocation = "_";
                self.isValidatedLocation = false;
                self.selectedContainerBlock = "location";
            }
            else if(dataBlock == "currentwork"){
                self.selectedMyOccupation = "_";
                self.isValidatedMyOccupation = false;
                self.selectedContainerBlock = "currentwork";
            }
            else if(dataBlock == "wish"){
                self.selectedWishOccupation = "_";
                self.isValidatedWishOccupation = false;
                self.selectedContainerBlock = "wish";
            }
            self.$ContainerArian.removeClass("mobileexpanded");
            self.update();
        });

        $(".arian_expandmobilebt").on("click", function(){
            self.$ContainerArian.toggleClass("mobileexpanded");
        });

        this.$ContainerArian.on("click", ".arian_block .value_group", function(){
            console.log("ici ???");
            self.displayOccupationGroupModal($(this).attr("data-group"));
            return false;
        });

        $(".location_validbt").on("click", function(){
            if(self.selectedLocation !== "_"){
                self.isValidatedLocation = true;
                self.selectedContainerBlock = self.findNextEmptyStep();
                self.update();
            }
        });

        $(".myoccupation_validbt").on("click", function(){
            self.isValidatedMyOccupation = true;
            self.update();
        });

        $(".myoccupation_cancelbt").on("click", function(){
            self.isValidatedMyOccupation = false;
            self.selectedMyOccupation = "_";
            self.update();
        });

        $(".wishoccupation_validbt").on("click", function(){
            self.isValidatedWishOccupation = true;
            self.update();
        });

        $(".wishoccupation_cancelbt").on("click", function(){
            self.isValidatedWishOccupation = false;
            self.selectedWishOccupation = "_";
            self.update();
        });

        this.$locationSelectbox.on("change", function(){
            self.selectLocation($(this).val());
        });

        this.$Cache.on("click", function(){
            self.closeOccupationGroupModal();
        });

        this.$OGModal.on("click", ".ogmodal_closebt", function(){
            self.closeOccupationGroupModal();
        });


        this.$ChangingcareerTalents.on("mouseenter, touchend", ".talent_help", function(){
            self.displayTalentRowHelp($(this).attr("data-row"));
        });

        this.$ChangingcareerTalents.on("mouseleave", ".talent_help", function(){
            $(".talent_row").removeClass("displayhelp")
                            .removeClass("opacified");
        });

        this.$ChangingcareerTalents.on("click", ".help_mobileclosebt", function(){
            $(".talent_row").removeClass("displayhelp")
                            .removeClass("opacified");
        });
    },

    selectLocation:function(countryCode){
        this.selectedLocation = countryCode;
    },

    selectMyOccupation:function(occupationCode){
        this.selectedMyOccupation = occupationCode;
        this.selectedContainerBlock = "currentworkValidate";
        this.update();
    },

    selectWishOccupation:function(occupationCode){
        this.selectedWishOccupation = occupationCode;
        this.selectedContainerBlock = "wishValidate";
        this.update();
    },

    displayOccupationGroupModal:function(group){
        var self = this;
        var slugGroup = _.str.slugify(App.utils.occupationgroups[group].label_en);   
        if(App.utils.countries[App.selectedLocation].occupationgroups.all[group] === undefined){
            alert("no data");
            return false;
        }     
        /*var $ogBlock = App.utils.getOGBlock(group, App.selectedLocation, "_", 0);
        if($ogBlock !== false){
            this.$OGModal.find(".occupationgroup_block_container").append($ogBlock);
        }else{
            return false;
        }*/
        this.$ogBlock.find(".bar_point").css("left", "0%");
        this.$OGModal.addClass("displayed");
        this.$OGModal.find(".occupationgroup_label_text").html(App.utils.occupationgroups[group].label_en);
        this.$OGModal.find(".occupationgroup_label_picto").css("background-image", "url(img/fields/"+slugGroup+"@2x.png)")
                                                            
        this.$OGModal.find(".occupationgroup_block_container .og_block").addClass("displayed");

        if(App.utils.countries[this.selectedLocation].occupationgroups.all[group] !== undefined){
            var finalInd = App.utils.countries[this.selectedLocation].occupationgroups.all[group].final_ind;
            this.$OGModal.find(".occupationgroup_label_growth").html("hard-to-find")
                                                                .attr("data-growth", "demand");
            if(finalInd < 0){
                this.$OGModal.find(".occupationgroup_label_growth").html("in-excess")
                                                                .attr("data-growth", "surplus");
            }
        }

        setTimeout(function(){
            App.utils.animateOGBlock(self.$ogBlock, group, App.selectedLocation);
        }, 600);
        
        this.$Cache.addClass("displayed");
    },

    closeOccupationGroupModal:function(){
        this.$OGModal.removeClass("displayed");
        this.$Cache.removeClass("displayed");
    },

    displayTalentRowHelp:function(dataRow){
        $(".talent_row").removeClass("displayhelp")
                        .addClass("opacified");
        console.log("displayTalentRowHelp", dataRow);
        var $row = $(".talent_row[data-row='"+dataRow+"']");
        $row.addClass("displayhelp")
            .removeClass("opacified");
    },

    findNextEmptyStep:function(){
        if(this.selectedLocation == "_"){
            return "location";
        }else if(this.selectedMyOccupation == "_"){
            return "currentwork";
        }else if(this.selectedWishOccupation == "_"){
            return "wish";
        }else{
            return "results";
        }
    }

};

module.exports = App;
window.App = App;