var ImbalancesSingleView = Backbone.View.extend({

    initialize:function(){
        var self = this;

    },

    render:function(){
        this.bindEvents();
    },

    updateImbalancesGraph:function(){
        var self = this;
        App.$ImbalancesGraph.find(".graph_bar_wrapper").remove();
        App.$ImbalancesGraphWrapper.attr("data-status", "single");
        App.appStatus = "single";
        App.$Container.attr("data-status", "single");

        var sortedLvl1 = _.sortBy(App.utils.countries[App.selectedLocation1].talents.lvl1, function(t){
            return -t.final_ind;
        });

        var max = _.max(sortedLvl1, function(t){ return t.final_ind; }).final_ind;
        var min = _.min(sortedLvl1, function(t){ return t.final_ind; }).final_ind;
        var lvl1Max = max;
        if(Math.abs(min) > max) lvl1Max = Math.abs(min);

        var mobileShift = 10;
        if(sortedLvl1[sortedLvl1.length - 1].final_ind < 0){
            mobileShift += Math.ceil((Math.abs(sortedLvl1[sortedLvl1.length - 1].final_ind) * App.imbalancesGraphParams.maxPctPos) / lvl1Max);   
        }

        if(App.isMobile){
            $(".graph_side[data-side='insurplus']").css("width", mobileShift+"%");
            $(".graph_side[data-side='indemand']").css("left", mobileShift+"%")
                                                .css("width", (100-mobileShift)+"%");
        }

        _.each(sortedLvl1, function(t, inc){
            var talentType = App.utils.talents[t.talent_id].type;
            if(_.indexOf(App.selectedTalents, talentType) > -1){
                var childrenTalents = App.utils.getChildrenTalents(t.talent_id);
                var $barWrapper = $("<div class='graph_bar_wrapper' data-talent='"+t.talent_id+"' data-nb-children='"+childrenTalents.length+"'><div class='graph_bars'><div class='graph_bar'><div class='bar_label'>"+App.utils.talents[t.talent_id].label_en+"</div><div class='bar_point'></div></div></div><div class='graph_subbars'><div class='subbars_fakefullwidth'></div><div class='subbars_fakeshadow'></div><div class='subbars_title'>Sub-skills</div></div></div>");
                App.$ImbalancesGraph.append($barWrapper);

                var $bar = $barWrapper.find(".graph_bar");
                
                $barWrapper.attr("data-pos", inc)
                            .css("z-index", inc);

                var way = "positive";
                if(t.final_ind < 0) way = "negative";
                var barWidth = (Math.abs(t.final_ind) * App.imbalancesGraphParams.maxPctPos) / lvl1Max;
                setTimeout(function(){
                    $barWrapper.addClass("displayed");
                    $barWrapper.find(".subbars_fakeshadow").css("width", mobileShift+"%");
                    $bar.css("width", barWidth+"%")
                        .attr("data-way", way)
                        .attr("data-value", t.final_ind);
                    if(App.isMobile){
                        if(way == "positive") $bar.css("left", mobileShift+"%");
                        else $bar.css("right", (100-mobileShift)+"%");
                    }
                }, 100*inc);

                var childrenCountryTalents = _.map(childrenTalents, function(t){
                    return {
                        id:t.id,
                        final_ind:App.utils.countries[App.selectedLocation1].talents.lvl2[t.id].final_ind
                    }
                });
                var sortedChildren = _.sortBy(childrenCountryTalents, function(ct){
                    return -ct.final_ind;
                });
                _.each(sortedChildren, function(ct){
                    var $subbarWrapper = $("<div class='graph_subbar_wrapper' data-talent='"+ct.id+"'><div class='graph_subbar'><div class='bar_label'>"+App.utils.talents[ct.id].label_en+"</div><div class='bar_point'></div></div></div>");
                    $barWrapper.find(".graph_subbars").append($subbarWrapper);

                    var barWidth = (Math.abs(ct.final_ind) * App.imbalancesGraphParams.maxPctPos) / lvl1Max;
                    var way = "positive";
                    if(t.final_ind < 0) way = "negative";
                    $subbarWrapper.find(".graph_subbar").attr("data-value", ct.final_ind)
                                                        .attr("data-width", barWidth)
                                                        .attr("data-way", way);
                    if(App.isMobile){
                        if(way == "positive") $subbarWrapper.find(".graph_subbar").css("left", mobileShift+"%");
                        else $subbarWrapper.find(".graph_subbar").css("right", (100-mobileShift)+"%");
                    }
                });
            }
        });

        App.updateGraphSides();
    },



    bindEvents:function(){
        var self = this;
    },

});

module.exports = ImbalancesSingleView;