var AppRouter = Backbone.Router.extend({

    routes: {
        "":"routeFromURL",
        ":location":"routeFromURL",
    },

    routeFromURL:function(location){
        if(location !== null && location !== undefined) App.selectedLocation = location;
        App.updateFromRouter();
    },
    
    updateRoute:function(triggerize){
        this.navigate( App.selectedLocation, {trigger: triggerize});
    },

});

module.exports = AppRouter;