var App = {
    $body:$("body"),

    init: function init() {
        this.isRendered = false;

        this.$App = $("#App");
        this.page = "imbalances";
        this.appStatus = "single";
        this.graphStatus = "";
        this.selectedLocation1 = "FR";
        this.selectedLocation2 = "_";
        this.selectedTalents = ["skills", "knowledge", "abilities"];
        this.selectedTalentID = "_";
        this.selectedComparisonType = "co";

        this.$Container = $("#Container");
        this.$locationSelectbox = $(".location_selectbox");
        this.$ImbalancesGraphWrapper = $("#ImbalancesGraphWrapper");
        this.$ImbalancesGraph = $("#ImbalancesGraph");
        this.$ComparisonSelector = $("#ComparisonSelector");
        this.$ComparisonSelectorSelectbox = $("#ComparisonSelectorSelectbox");
        this.$LocationSelectorModal = $("#LocationSelectorModal");
        this.$Cache = $("#Cache");

        this.oneSkillClicked = false;

        this.imbalancesGraphParams = {
            topMargin:30,
            barHeight:30,
            maxPctPos:45,
            maxPctPosIG:50,
            scrollShift:40
        };

        this.isMobile = false;
        if($(window).width() <= 700){
            this.isMobile = true;
            this.imbalancesGraphParams.maxPctPos = 40;
            this.imbalancesGraphParams.maxPctPosIG = 35;
            this.imbalancesGraphParams.topMargin = 60;
            this.imbalancesGraphParams.scrollShift = 80;
        }

        var Utils = require("utils");
        this.utils = new Utils();

        var HeaderView = require("views/headerView");
        this.headerView = new HeaderView();

        var ImbalancesSingleView = require("views/imbalancesSingleView");
        this.imbalancesSingleView = new ImbalancesSingleView();

        var ImbalancesComparisonView = require("views/imbalancesComparisonView");
        this.imbalancesComparisonView = new ImbalancesComparisonView();

        var ImbalancesModalView = require("views/imbalancesModalView");
        this.imbalancesModalView = new ImbalancesModalView();

        var Router = require("routerImbalances");
        this.router = new Router();

        Backbone.history.start();
    },

    updateFromRouter:function(){
        var self = this;
        if(!this.isRendered){
            this.utils.gatherData(function(){
                self.render();
            });
        }
    },

    render:function(){
        console.log("render");
        this.isRendered = true;

        this.headerView.render();
        this.imbalancesModalView.render();

        $(".header_link").removeClass("selected");
        $(".header_link[data-page='"+this.page+"']").addClass("selected");
        this.renderLocationSelectbox();

        setTimeout(function(){
            $(".imbalances_help").addClass("displayed");
        }, 3000);

        this.bindEvents();
        this.update();
    },

    renderLocationSelectbox:function(){
        var self = this;
        _.each(App.utils.countries, function(c){
            var $option = $("<option value='"+c.code+"'>"+c.label_en+"</option>");
            self.$locationSelectbox.append($option);
        });
        $(".location_selectbox[data-location='2']").prepend("<option value='_'>Compare country</option>");

        $(".location_selectbox[data-location='1']").val(self.selectedLocation1);
        $(".location_selectbox[data-location='2']").val(self.selectedLocation2);
    },

    update:function(){
        var self = this;
        this.updateSelectedTalents();
        this.updateComparisonSelectorSelectbox();

        $(".selector_location_block[data-location='1'] .location_value").html(App.utils.countries[this.selectedLocation1].label_en);
        if(this.selectedLocation2 !== "_"){
            $(".selector_location_block[data-location='2']").removeClass("default");
            $(".selector_location_block[data-location='2'] .location_value").html(App.utils.countries[this.selectedLocation2].label_en);
            this.utils.getDBCountryTalents(this.selectedLocation1, function(){
                self.utils.getDBCountryTalents(self.selectedLocation2, function(){
                    self.utils.getDBCountryOccupationgroups(self.selectedLocation1, function(){
                        self.utils.getDBCountryOccupationgroups(self.selectedLocation2, function(){
                            self.imbalancesComparisonView.updateImbalancesComparisonGraph();
                        });
                    });
                });
            });
        }
        else{
            $(".selector_location_block[data-location='2']").addClass("default");
            $(".selector_location_block[data-location='2'] .location_value").html("Compare <span class='txt_nomobile'>with another location</span>");
            this.utils.getDBCountryTalents(this.selectedLocation1, function(){
                self.utils.getDBCountryOccupationgroups(self.selectedLocation1, function(){
                    self.imbalancesSingleView.updateImbalancesGraph();
                });
            });
        }

        this.headerView.update(this.selectedLocation1);
        this.$ImbalancesGraphWrapper.attr("data-oneskillclicked", this.oneSkillClicked);
        this.router.updateRoute();
    },

    updateSelectedTalents:function(){
        $(".filtertype").removeClass("selected");
        _.each(this.selectedTalents, function(t){
            $(".filtertype[data-talent='"+t+"']").addClass("selected");
        });
    },

    updateComparisonSelectorSelectbox:function(){
        this.$ComparisonSelectorSelectbox.empty();
        this.$ComparisonSelectorSelectbox.append("<option value='co'>"+App.utils.countries[this.selectedLocation1].label_en+"</option>");
        this.$ComparisonSelectorSelectbox.append("<option value='ig'>Imbalance gap</option>");
        this.$ComparisonSelectorSelectbox.val(this.selectedComparisonType);
    },

    updateGraphSides:function(){
        if(this.$ImbalancesGraph.height() >= this.$ImbalancesGraphWrapper.height()){
            $(".graph_side").css("height", this.$ImbalancesGraph.height() + this.imbalancesGraphParams.topMargin);
        }else{
            $(".graph_side").css("height", "100%");
        }
        
    },

    bindEvents:function(){
        var self = this;

        this.$ImbalancesGraph.on("click", ".graph_bar_wrapper", function(){
            self.oneSkillClicked = true;
            self.$ImbalancesGraphWrapper.attr("data-oneskillclicked", self.oneSkillClicked);
            if(App.graphStatus != "single-shrinked"){
                var $barWrapper = $(this);
                var dataChildren = $barWrapper.attr("data-nb-children");

                //if(dataChildren == "0") return false;

                if($barWrapper.hasClass("expanded")){
                    self.shrinkBarWrapper();
                }else{
                    self.expandBarWrapper($barWrapper);   
                }
            }else{
                self.shrinkBarWrapper();
            }
        });

        /* LOCATION SELECTBOX */
        this.$locationSelectbox.on("change", function(){
            var dataLocation = $(this).attr("data-location");
            if(dataLocation == "1"){
                self.$LocationSelectorModal.find(".title_country").html(App.utils.countries[$(this).val()].label_en);
            }
            self.$LocationSelectorModal.attr("data-selectedlocation", $(this).val())
                                        .attr("data-haslocation", "true");
        });
        this.$LocationSelectorModal.find(".modal_validbt").on("click", function(){
            var dataLocation = self.$LocationSelectorModal.attr("data-location");
            App["selectedLocation"+dataLocation] = self.$LocationSelectorModal.attr("data-selectedlocation");
            self.hideLocationSelectorModal();
            self.shrinkBarWrapper();
            $("#ImbalancesGraphWrapper")[0].scrollTo(0,0);
            self.update();
        });
        this.$LocationSelectorModal.find(".modal_cancelbt").on("click", function(){
            self.hideLocationSelectorModal();
        });
        /* END : LOCATION SELECTBOX */

        $(".filtertype").on("click", function(){
            self.shrinkBarWrapper();
            self.toggleTalent($(this).attr("data-talent"));
        });

        this.$ComparisonSelectorSelectbox.on("change", function(){
            self.selectedComparisonType = $(this).val();
            self.shrinkBarWrapper();
            $("#ImbalancesGraphWrapper")[0].scrollTo(0,0);
            console.log("before update");
            App.update();
        });

        $(".selector_location_block").on("click", function(){
            var timeout = 0;
            if(App.graphStatus == "single-shrinked"){
                self.shrinkBarWrapper();
                timeout = 900;
            }

            var dataLocation = $(this).attr("data-location");
            setTimeout(function(){
                self.displayLocationSelectorModal(dataLocation);
            }, timeout);
        });

        $(".location_deletebt").on("mouseenter", function(){
            $(".selector_location_block[data-location='2']").addClass("disopacified");
        });
        $(".location_deletebt").on("mouseleave", function(){
            $(".selector_location_block[data-location='2']").removeClass("disopacified");
        });

        $(".location_deletebt").on("click", function(){
            var timeout = 0;
            if(App.graphStatus == "single-shrinked"){
                self.shrinkBarWrapper();
                timeout = 900;
            }
            setTimeout(function(){
                self.removeComparisonLocation();
            }, timeout);
            return false;
        });

        this.$Cache.on("click", function(){
            self.hideLocationSelectorModal();
        });

        this.$ImbalancesGraph.on("click", ".graph_subbars", function(){
            return false;
        });
    },

    expandBarWrapper:function($barWrapper){
        var self = this;
        this.selectedTalentID = $barWrapper.attr("data-talent");

        var topOffset = this.$ImbalancesGraphWrapper.scrollTop() + ($barWrapper.offset().top - this.$ImbalancesGraphWrapper.offset().top - App.imbalancesGraphParams.scrollShift);
        var talentID = $barWrapper.attr("data-talent");
        this.utils.getDBCountryTalentOccupationgroups(this.selectedLocation1, talentID, function(){
            $(".graph_bar_wrapper").removeClass("expanded")
                                    .removeClass("prexpanded");

            $barWrapper.addClass("prexpanded");

            setTimeout(function(){
                App.graphStatus = "single-shrinked";
                self.$ImbalancesGraphWrapper.attr("data-status", "single-shrinked");
                self.$ImbalancesGraph.addClass("shrinked");
                self.$ImbalancesGraphWrapper.animate({scrollTop:topOffset+"px"}, 300);
            }, 50);

            setTimeout(function(){
                
            }, 600);

            setTimeout(function(){
                $barWrapper.addClass("expanded");
                var $graphSubbars = $barWrapper.find(".graph_subbars");
                var $subbarsFakefullwidth = $graphSubbars.find(".subbars_fakefullwidth");
                var filledWidth = $graphSubbars.width() + $graphSubbars.offset().left;
                var fakeWidth = $(window).width() - filledWidth;
                $subbarsFakefullwidth.css("width", fakeWidth);
                $graphSubbars.find(".splitter_fake").css("width", fakeWidth);
                var subbars = $barWrapper.find(".graph_subbar");
                _.each(subbars, function(subbar){
                    var $subbar = $(subbar);
                    var barWidth = parseFloat($subbar.attr("data-width"));
                    $subbar.css("width", barWidth+"%");
                })
                self.imbalancesModalView.displayModal(talentID);
                self.updateGraphSides();
            }, 800);
        });
    },

    shrinkBarWrapper:function(){
        $(".graph_bar_wrapper").removeClass("expanded")
                                .removeClass("prexpanded");
        this.imbalancesModalView.hideModal();
        this.$ImbalancesGraph.removeClass("shrinked");
        App.graphStatus = "single";
        this.$ImbalancesGraphWrapper.attr("data-status", "single");
        $("#ImbalancesGraphWrapper")[0].scrollTo(0,0);
        this.updateGraphSides();
    },

    toggleTalent:function(talent){
        if(_.indexOf(this.selectedTalents, talent) > -1){
            this.selectedTalents = _.without(this.selectedTalents, talent);
        }else{
            this.selectedTalents.push(talent);
        }
        this.update();
    },

    displayLocationSelectorModal:function(dataLocation){
        this.$Cache.addClass("displayed");
        this.$LocationSelectorModal.find(".title_country").html(App.utils.countries[this.selectedLocation1].label_en);
        $(".location_selectbox[data-location='2']").val("_");
        this.$locationSelectbox.find("option").attr("disabled", false);

        if(dataLocation == "2"){
            if(this.selectedLocation1 !== "_") this.$locationSelectbox.find("option[value='"+this.selectedLocation1+"']").attr("disabled", "disabled");
        }else{
            if(this.selectedLocation2 !== "_") this.$locationSelectbox.find("option[value='"+this.selectedLocation2+"']").attr("disabled", "disabled");
        }

        this.$LocationSelectorModal.addClass("displayed")
                                    .attr("data-location", dataLocation)
                                    .attr("data-haslocation", "false");
    },

    hideLocationSelectorModal:function(){
        this.$Cache.removeClass("displayed");
        this.$LocationSelectorModal.removeClass("displayed");
    },

    removeComparisonLocation:function(){
        this.selectedLocation2 = "_";
        this.shrinkBarWrapper();
        $("#ImbalancesGraphWrapper")[0].scrollTo(0,0);
        this.update();
    }

};

module.exports = App;
window.App = App;