
var Utils = Backbone.View.extend({

    initialize:function(){
    },

    computeDataCountryTalent:function(){
        var self = this;

        self.countries = {};
        self.talents = {};
        self.talentsCountries = {};

        d3.csv("data/country_talent.csv", function(data){
            if(self.countries[data.country] == undefined){
                self.countries[data.country] = {
                    code:data.country,
                    label_en:""
                };
            }

            if(self.talents[data.label_en] == undefined){
                self.talents[data.label_en] = {
                    id:data.elementid,
                    type:data.dimension,
                    level:data.level,
                    label_en:data.label_en,
                    description:""
                }
                self.talentsCountries[data.label_en] = {};
            }

            if(self.talentsCountries[data.label_en][data.country] === undefined){
                self.talentsCountries[data.label_en][data.country] = {
                    talent_id:self.talents[data.label_en].id,
                    country_code:data.country,
                    final_ind:data.final_ind
                }
            }

        }, function(error, rows) {
            console.log(error, rows);
            self.gatherDescription();
        });
    },

    gatherDescription:function(){
        var self = this;
        d3.csv("data/talents_description.csv", function(data){
            if(self.talents[data.label_en] === undefined) console.log("problemo", data.label_en);
            else self.talents[data.label_en].description = data.description;
        }, function(error, rows) {
            console.log(error, rows);
            self.generateCSVS();
        });
    },

    generateCSVS:function(){
        var self = this;

        this.countriesCSV = this.objToCSV(this.countries, ["code", "label_en"]);
        this.talentsCSV = this.objToCSV(this.talents, ["id", "type", "level", "label_en", "description"]);
        this.talentCountryCSV = this.objToCSVSpecific(this.talentsCountries, ["talent_id", "country_code", "final_ind"]);
    },

    objToCSV:function(obj, csvHeadValues){
        var csvHead = '';
        var csvLines = '';
        var incObj = 0;
        _.each(obj, function(objValues, objIndex){
            _.each(csvHeadValues, function(headValue, inc){
                if(incObj == 0){
                    if(inc == csvHeadValues.length - 1) csvHead += '"'+headValue+'"\n';
                    else csvHead += '"'+headValue+'",';
                }
                if(inc == csvHeadValues.length - 1){
                    csvLines += '"'+objValues[headValue] + '"\n';
                }else{
                    csvLines += '"'+objValues[headValue] + '",';
                }
            });
            incObj += 1;
        });
        return csvHead + csvLines;
    },

    objToCSVSpecific:function(obj, csvHeadValues){
        var csvHead = '';
        var csvLines = '';
        var csvAttrValues = csvHeadValues.slice(2);

        var incObj = 0;
        _.each(obj, function(obj2ndLevels, objIndex){
            _.each(obj2ndLevels, function(obj2ndLevelValues, inc){
                _.each(csvHeadValues, function(headValue, inc){
                    if(incObj == 0){
                        if(inc == csvHeadValues.length - 1) csvHead += '"'+headValue+'"\n';
                        else csvHead += '"'+headValue+'",';
                    }
                    if(inc == csvHeadValues.length - 1){
                        csvLines += '"'+obj2ndLevelValues[headValue] + '"\n';
                    }else{
                        csvLines += '"'+obj2ndLevelValues[headValue] + '",';
                    }
                });
                incObj += 1;
            });
        });
        return csvHead + csvLines;
    }
});

module.exports = Utils;