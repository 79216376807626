var ImbalancesComparisonView = Backbone.View.extend({

    initialize:function(){
        var self = this;
        this.defaultIGMinHeight = 170;
        this.defaultIGFirstMargin = 0;
    },

    render:function(){
        this.bindEvents();
    },

    updateImbalancesComparisonGraph:function(){
        var self = this;

        App.$ImbalancesGraph.find(".graph_bar_wrapper").remove();

        if(App.selectedComparisonType == "co"){
        	this.updateFromCountry();
        }else{
        	this.updateFromImbalanceGap();
        }
    },

    updateFromImbalanceGap:function(){
    	var self = this;

    	this.comparisonImbalancesLoca1 = [];
    	this.comparisonImbalancesLoca2 = [];

    	_.each(App.utils.countries[App.selectedLocation1].talents.lvl1, function(t){
    		var talentType = App.utils.talents[t.talent_id].type;
            if(_.indexOf(App.selectedTalents, talentType) > -1){
	    		var loca2FinalInd = App.utils.countries[App.selectedLocation2].talents.all[t.talent_id].final_ind;
	    		var imbalanceGap = Math.abs(t.final_ind - loca2FinalInd);

	    		if(t.final_ind > loca2FinalInd){
	    			self.comparisonImbalancesLoca1.push({
		    			loca1:t.final_ind,
		    			loca2:loca2FinalInd,
		    			imbalanceGap:imbalanceGap,
		    			talent:t
		    		});
	    		}else{
	    			self.comparisonImbalancesLoca2.push({
		    			loca1:t.final_ind,
		    			loca2:loca2FinalInd,
		    			imbalanceGap:imbalanceGap,
		    			talent:t
		    		});
	    		}
	    	}
    	});

    	this.sortedComparisonImbalancesLoca1 = _.sortBy(this.comparisonImbalancesLoca1, function(ci){
    		return -ci.imbalanceGap;
    	});
    	this.sortedComparisonImbalancesLoca2 = _.sortBy(this.comparisonImbalancesLoca2, function(ci){
    		return ci.imbalanceGap;
    	});

        /*var mobileShift = 10;
        if(this.sortedComparisonImbalancesLoca2[this.sortedComparisonImbalancesLoca2.length - 1] !== undefined){
            console.log("loca 2", this.sortedComparisonImbalancesLoca2[this.sortedComparisonImbalancesLoca2.length - 1])
            mobileShift += Math.ceil((Math.abs(sortedLvl1[sortedLvl1.length - 1].final_ind) * App.imbalancesGraphParams.maxPctPos) / lvl1Max);   
        }
        if(App.isMobile){
            $(".graph_side[data-side='insurplus']").css("width", mobileShift+"%");
            $(".graph_side[data-side='indemand']").css("left", mobileShift+"%")
                                                .css("width", (100-mobileShift)+"%");
        }*/

        if(this.sortedComparisonImbalancesLoca1.length > 0) this.maxImbalance = this.sortedComparisonImbalancesLoca1[0].imbalanceGap;
        else this.maxImbalance = 0;

        if(this.sortedComparisonImbalancesLoca2.length > 0 && this.sortedComparisonImbalancesLoca2[this.sortedComparisonImbalancesLoca2.length - 1].imbalanceGap > this.maxImbalance){
            this.maxImbalance = this.sortedComparisonImbalancesLoca2[this.sortedComparisonImbalancesLoca2.length - 1].imbalanceGap;
        }

        this.updateGraphSidesIG();
    	var loca1Timeout = this.updateFromImbalanceGapLocation("loca1");
    	setTimeout(function(){
    		self.updateFromImbalanceGapLocation("loca2");
    	}, loca1Timeout);
    },

    updateGraphSidesIG:function(){
    	$(".graph_side_ig[data-side='moreloca1'] .side_title .graph_side_ig_loca1").html(App.utils.countries[App.selectedLocation1].label_en);
    	$(".graph_side_ig[data-side='moreloca2'] .side_title .graph_side_ig_loca2").html(App.utils.countries[App.selectedLocation2].label_en);

    	var moreLoca1Height = (this.sortedComparisonImbalancesLoca1.length * App.imbalancesGraphParams.barHeight) + App.imbalancesGraphParams.topMargin;
    	var moreLoca2Height = this.sortedComparisonImbalancesLoca2.length * App.imbalancesGraphParams.barHeight;

        console.log("locaheight", moreLoca1Height, this.defaultIGMinHeight);
        if(moreLoca1Height < this.defaultIGMinHeight){
            this.defaultIGFirstMargin = this.defaultIGMinHeight - moreLoca1Height;
        }else{
            this.defaultIGFirstMargin = 0;
        }

    	$(".graph_side_ig[data-side='moreloca1']").css("height", (moreLoca1Height + this.defaultIGFirstMargin)+"px");
    	$(".graph_side_ig[data-side='moreloca2']").css("height", (moreLoca2Height+10)+"px")
    											.css("top", (moreLoca1Height-10+this.defaultIGFirstMargin)+"px");
    },

    updateFromImbalanceGapLocation:function(locationNum){
    	var self = this;
        App.graphStatus = "comparison-ig";
    	App.$ImbalancesGraphWrapper.attr("data-status", "comparison-ig");
        App.appStatus = "comparison-ig";
        App.$Container.attr("data-status", "comparison-ig");

    	var locaValues = this.sortedComparisonImbalancesLoca1;
    	if(locationNum == "loca2")  locaValues = this.sortedComparisonImbalancesLoca2;

    	var nbDisplayed = 0;
    	_.each(locaValues, function(ci, inc){
        	nbDisplayed += 1;
            var childrenTalents = App.utils.getChildrenTalents(ci.talent.talent_id);
            var $barWrapper = $("<div class='graph_bar_wrapper' data-talent='"+ci.talent.talent_id+"' data-nb-children='"+childrenTalents.length+"'><div class='graph_bars'><div class='graph_barcomp'><div class='bar_label'>"+App.utils.talents[ci.talent.talent_id].label_en+"</div><div class='bar_point1'></div><div class='bar_point2'></div></div></div><div class='graph_subbars'><div class='subbars_fakefullwidth'></div><div class='subbars_fakeshadow'></div><div class='subbars_title'>Sub-dimensions</div></div></div>");
            console.log(locationNum, nbDisplayed);
            if(locationNum == "loca1" && nbDisplayed == 1) $barWrapper.css("margin-top", self.defaultIGFirstMargin+"px");
            App.$ImbalancesGraph.append($barWrapper);

            var $bar = $barWrapper.find(".graph_barcomp");
            $barWrapper.attr("data-pos", inc)
                        .css("z-index", inc);
            var barWidth = (Math.abs(ci.imbalanceGap) * App.imbalancesGraphParams.maxPctPosIG) / self.maxImbalance;
            $bar.css("left", "50%");

            setTimeout(function(){
                $barWrapper.addClass("displayed");
                $bar.css("width", barWidth+"%")
                	.css("margin-left", -barWidth/2+"%")
                	.attr("data-way", "imbalance-"+locationNum)
                    .attr("data-value", ci.imbalanceGap);
            }, 100*inc);

            //APPEND SUBBARS
            
            var childrenCountryTalents = _.map(childrenTalents, function(t){
                return {
                    id:t.id,
                    imbalanceGap:App.utils.countries[App.selectedLocation1].talents.lvl2[t.id].final_ind - App.utils.countries[App.selectedLocation2].talents.lvl2[t.id].final_ind
                }
            });
            var sortedChildren = _.sortBy(childrenCountryTalents, function(ct){
                return -ct.imbalanceGap;
            });

            var igSplit = false;
            var $splitterDiv = $("<div class='graph_splitter' data-splitting='default'><div class='splitter_legend' data-legend='co1'>Harder ton find<br />in "+App.utils.countries[App.selectedLocation1].label_en+"</div><div class='splitter_legend' data-legend='co2'>Harder ton find<br />in "+App.utils.countries[App.selectedLocation2].label_en+"</div><div class='splitter_fake'></div></div>");

            var inc = 0;
            _.each(sortedChildren, function(ct){
                if(!igSplit && ct.imbalanceGap < 0){
                    if(inc == 0) $splitterDiv.attr("data-splitting", "co2");
                    $barWrapper.find(".graph_subbars").append($splitterDiv);
                    igSplit = true;
                }
                var $subbarWrapper = $("<div class='graph_subbar_wrapper' data-talent='"+ct.id+"'><div class='graph_subbar graph_subbar_comp'><div class='bar_label'>"+App.utils.talents[ct.id].label_en+"</div><div class='bar_point1'></div><div class='bar_point2'></div></div></div>");
                $barWrapper.find(".graph_subbars").append($subbarWrapper);
                
                var barWidth = (Math.abs(ct.imbalanceGap) * App.imbalancesGraphParams.maxPctPosIG) / self.maxImbalance;
                var $subBar = $subbarWrapper.find(".graph_subbar");

                var locationNum = "loca1";
                if(ct.imbalanceGap < 0) locationNum = "loca2";

                $subBar.css("left", "50%")
                        .css("width", barWidth+"%")
                        .css("margin-left", -barWidth/2+"%")
                        .attr("data-way", "imbalance-"+locationNum)
                        .attr("data-value", ci.imbalanceGap);
                 inc += 1;
            });

            if(!igSplit){
                $splitterDiv.attr("data-splitting", "co1");
                $barWrapper.find(".graph_subbars").append($splitterDiv);
            }
    	});

    	return 100*nbDisplayed;
    },

    updateFromCountry:function(){
        console.log("updatefromcountry");
        App.graphStatus = "comparison";
    	App.$ImbalancesGraphWrapper.attr("data-status", "comparison");
        App.appStatus = "comparison";
        App.$Container.attr("data-status", "comparison");

        var sortedLvl1Loca1 = _.sortBy(App.utils.countries[App.selectedLocation1].talents.lvl1, function(t){
            return -t.final_ind;
        });
        var sortedLvl1Loca2 = _.sortBy(App.utils.countries[App.selectedLocation2].talents.lvl1, function(t){
            return -t.final_ind;
        });

        var maxLoca1 = _.max(sortedLvl1Loca1, function(t){ return t.final_ind; }).final_ind;
        var maxLoca2 = _.max(sortedLvl1Loca2, function(t){ return t.final_ind; }).final_ind;
        var minLoca1 = _.min(sortedLvl1Loca1, function(t){ return t.final_ind; }).final_ind;
        var minLoca2 = _.min(sortedLvl1Loca2, function(t){ return t.final_ind; }).final_ind;

        var maxLoca = maxLoca1;
        if(maxLoca2 > maxLoca1) maxLoca = maxLoca2;

        var minLoca = minLoca1;
        if(minLoca2 < minLoca1) minLoca = minLoca2;

        var lvl1Max = maxLoca;
        if(Math.abs(minLoca) > maxLoca) lvl1Max = Math.abs(minLoca);

        var refAxisLeft = 50;
        var refAxisRight = 50;
        var mobileShift = 10;
        console.log("updatefromcountry minloca", minLoca, maxLoca);
        if(minLoca < 0){
            mobileShift += Math.ceil((Math.abs(minLoca) * App.imbalancesGraphParams.maxPctPos) / lvl1Max);   
        }
        
        if(App.isMobile){
            refAxisRight = 100 - mobileShift;
            refAxisLeft = mobileShift;
            $(".graph_side[data-side='insurplus']").css("width", mobileShift+"%");
            $(".graph_side[data-side='indemand']").css("left", mobileShift+"%")
                                                .css("width", (100-mobileShift)+"%");
        }
        _.each(sortedLvl1Loca1, function(t, inc){
            var talentType = App.utils.talents[t.talent_id].type;
            if(_.indexOf(App.selectedTalents, talentType) > -1){
                var childrenTalents = App.utils.getChildrenTalents(t.talent_id);
                var $barWrapper = $("<div class='graph_bar_wrapper' data-talent='"+t.talent_id+"' data-nb-children='"+childrenTalents.length+"'><div class='graph_bars'><div class='graph_barcomp'><div class='bar_label'>"+App.utils.talents[t.talent_id].label_en+"</div><div class='bar_point1'></div><div class='bar_point2'></div></div></div><div class='graph_subbars'><div class='subbars_fakefullwidth'></div><div class='subbars_fakeshadow'></div><div class='subbars_title'>Sub-dimensions</div></div></div>");
                App.$ImbalancesGraph.append($barWrapper);

                var $bar = $barWrapper.find(".graph_barcomp");
                $barWrapper.attr("data-pos", inc)
                            .css("z-index", inc);
                if(App.isMobile){
                    $barWrapper.find(".subbars_fakeshadow").css("width", mobileShift+"%");
                }
                var barWidth = (Math.abs(t.final_ind - App.utils.countries[App.selectedLocation2].talents.all[t.talent_id].final_ind) * App.imbalancesGraphParams.maxPctPos) / lvl1Max;

                var way = "increasing";
                var refPoint = (t.final_ind*App.imbalancesGraphParams.maxPctPos) / lvl1Max;
                if(t.final_ind > App.utils.countries[App.selectedLocation2].talents.all[t.talent_id].final_ind){
                    way = "decreasing";
                    if(t.final_ind > 0){
                        $bar.css("right", refAxisRight - refPoint + "%");
                        if(((refAxisRight - refPoint) +  barWidth) > 50) $bar.attr("data-align", "left");
                        else $bar.attr("data-align", "right");
                    }else{
                        refPoint = (Math.abs(t.final_ind)*App.imbalancesGraphParams.maxPctPos) / lvl1Max;
                        $bar.css("right", refAxisRight + refPoint + "%");
                        if(((refAxisRight + refPoint) +  barWidth) > 50) $bar.attr("data-align", "left");
                        else $bar.attr("data-align", "right");
                    }
                }else{
                    if(t.final_ind > 0){
                        $bar.css("left", refAxisLeft + refPoint + "%");
                        if(((refAxisLeft + refPoint) +  barWidth) > 50) $bar.attr("data-align", "right");
                        else $bar.attr("data-align", "left");
                    }else{
                        refPoint = (Math.abs(t.final_ind)*App.imbalancesGraphParams.maxPctPos) / lvl1Max;
                        $bar.css("left", refAxisLeft - refPoint + "%");
                        if(((refAxisLeft - refPoint) +  barWidth) > 50) $bar.attr("data-align", "right");
                        else $bar.attr("data-align", "left");
                    }
                }

                setTimeout(function(){
                    $barWrapper.addClass("displayed");
                    $bar.css("width", barWidth+"%")
                        .attr("data-way", way)
                        .attr("data-value", t.final_ind);
                }, 100*inc);

                //APPEND SUBBARS
                var childrenCountryTalents = _.map(childrenTalents, function(t){
                    return {
                        id:t.id,
                        final_ind_loca1:App.utils.countries[App.selectedLocation1].talents.lvl2[t.id].final_ind,
                        final_ind_loca2:App.utils.countries[App.selectedLocation2].talents.lvl2[t.id].final_ind,
                    }
                });
                var sortedChildren = _.sortBy(childrenCountryTalents, function(ct){
                    return -ct.final_ind_loca1;
                });
                _.each(sortedChildren, function(ct){
                    var $subbarWrapper = $("<div class='graph_subbar_wrapper' data-talent='"+ct.id+"'><div class='graph_subbar graph_subbar_comp'><div class='bar_label'>"+App.utils.talents[ct.id].label_en+"</div><div class='bar_point1'></div><div class='bar_point2'></div></div></div>");
                    $barWrapper.find(".graph_subbars").append($subbarWrapper);
                    var barWidth = (Math.abs(ct.final_ind_loca1 - ct.final_ind_loca2) * App.imbalancesGraphParams.maxPctPos) / lvl1Max;

                    var way = "increasing";
	                var refPoint = (ct.final_ind_loca1*App.imbalancesGraphParams.maxPctPos) / lvl1Max;
	                var $subBar = $subbarWrapper.find(".graph_subbar");
	                if(ct.final_ind_loca1 >ct.final_ind_loca2){
	                    way = "decreasing";
	                    if(ct.final_ind_loca1 > 0){
	                        $subBar.css("right", refAxisRight - refPoint + "%");
                            if(((refAxisRight - refPoint) +  barWidth) > 50) $subBar.attr("data-align", "left");
                            else $subBar.attr("data-align", "right");
	                    }else{
	                        refPoint = (Math.abs(ct.final_ind_loca1)*App.imbalancesGraphParams.maxPctPos) / lvl1Max;
	                        $subBar.css("right", refAxisRight - refPoint + "%");
                            if(((refAxisRight + refPoint) +  barWidth) > 50) $subBar.attr("data-align", "left");
                            else $subBar.attr("data-align", "right");
	                    }
	                }else{
	                    if(ct.final_ind_loca1 > 0){
	                        $subBar.css("left", refAxisLeft + refPoint + "%");
                            if(((refAxisLeft + refPoint) +  barWidth) > 50) $subBar.attr("data-align", "right");
                            else $subBar.attr("data-align", "left");
	                    }else{
	                        refPoint = (Math.abs(ct.final_ind_loca1)*App.imbalancesGraphParams.maxPctPos) / lvl1Max;
	                        $subBar.css("left", refAxisLeft - refPoint + "%");
                            if(((refAxisLeft - refPoint) +  barWidth) > 50) $subBar.attr("data-align", "right");
                            else $subBar.attr("data-align", "left");
	                    }
	                }

                    $subBar.attr("data-value", ct.final_ind)
                            .attr("data-width", barWidth)
                            .attr("data-way", way);
                });
            }
        });

        App.updateGraphSides();
    },


    bindEvents:function(){
        var self = this;
    },

});

module.exports = ImbalancesComparisonView;