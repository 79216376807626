var App = {
    $body:$("body"),

    init: function init() {
        this.$App = $("#App");
        this.page = "press";
        this.isRendered = false;
        this.selectedLocation1 = "_";

        var Utils = require("utils");
        this.utils = new Utils();

        var HeaderView = require("views/headerView");
        this.headerView = new HeaderView();

        var Router = require("routerImbalances");
        this.router = new Router();

        Backbone.history.start();
    },

    updateFromRouter:function(){
        var self = this;
        if(!this.isRendered){
            this.utils.gatherData(function(){
                self.render();
            });
        }
    },

    render:function(){
        this.isRendered = true;
        $(".header_link").removeClass("selected");
        $(".header_link[data-page='"+this.page+"']").addClass("selected");
        
        this.headerView.render();
        this.bindEvents();
        this.update();
    },

    update:function(){
        var self = this;    
        this.headerView.update(this.selectedLocation1);       
    },

    bindEvents:function(){
        var self = this;
    },


};

module.exports = App;
window.App = App;