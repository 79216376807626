var App = {
    $body:$("body"),

    init: function init() {
        alert("ok");
        this.$App = $("#App");

        Backbone.history.start();
    },

    updateFromRouter:function(){
        var self = this;
    },

};

module.exports = App;
window.App = App;